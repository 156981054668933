import revive_payload_client_67FeZSDYcm from "/Users/saltytostitos/dev/error-logger/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_drizzle-orm@0.38.4_@clo_41fd7806e2f060ccc92685bcc0817478/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_RwpxrAFB17 from "/Users/saltytostitos/dev/error-logger/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_drizzle-orm@0.38.4_@clo_41fd7806e2f060ccc92685bcc0817478/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_KKU4nHsj7K from "/Users/saltytostitos/dev/error-logger/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_drizzle-orm@0.38.4_@clo_41fd7806e2f060ccc92685bcc0817478/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_5wczznFS4Y from "/Users/saltytostitos/dev/error-logger/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_drizzle-orm@0.38.4_@clo_41fd7806e2f060ccc92685bcc0817478/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_dO1A0BsYpQ from "/Users/saltytostitos/dev/error-logger/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_drizzle-orm@0.38.4_@clo_41fd7806e2f060ccc92685bcc0817478/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_lydt9k76ah from "/Users/saltytostitos/dev/error-logger/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_drizzle-orm@0.38.4_@clo_41fd7806e2f060ccc92685bcc0817478/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_26W8H7Nypw from "/Users/saltytostitos/dev/error-logger/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_drizzle-orm@0.38.4_@clo_41fd7806e2f060ccc92685bcc0817478/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/Users/saltytostitos/dev/error-logger/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_hmWJ5xdkdx from "/Users/saltytostitos/dev/error-logger/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_drizzle-orm@0.38.4_@clo_41fd7806e2f060ccc92685bcc0817478/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_67FeZSDYcm,
  unhead_RwpxrAFB17,
  router_KKU4nHsj7K,
  payload_client_5wczznFS4Y,
  navigation_repaint_client_dO1A0BsYpQ,
  check_outdated_build_client_lydt9k76ah,
  chunk_reload_client_26W8H7Nypw,
  components_plugin_zlvi6dcIsi,
  prefetch_client_hmWJ5xdkdx
]